import { http } from '@/lib/http'
import { ComputeEventParamsType, ComputeAccountParamsType, ComputeAggregationParamsType } from '#/FinancialsTypes'
import {
  ReportIssueType,
  CurrencyItemType,
  DuplicateListType,
  DashboardDataType,
  TransactionListType,
  TransactionDetailType,
  AccountMappingItemType
} from '#/ReportsTypes'
import { EntityDetailType } from '#/EntityType'

export default {
  /**
   * 获取entity详情
   * @param {number} entityId 主体id
   */
  getEntityDetail: (entityId: number) => {
    return http.request<ResponseData<EntityDetailType>>('get', `/entity/${entityId}`)
  },
  /**
   * 获取currency列表
   * @param {number} entityId 主体id
   */
  getCurrencyList: (entityId: number) => {
    return http.request<ResponseData<CurrencyItemType[]>>('get', `/entity/${entityId}/currency`)
  },
  /**
   * 获取balance-sheet
   * @param {number} entityId 主体id
   */
  getBalanceSheet: (entityId: number) => {
    return http.request('get', `/entity/${entityId}/balance-sheet`)
  },
  /**
   * 获取income-statement
   * @param {number} entityId 主体id
   * @returns
   */
  getIncomeStatement: (entityId: number) => {
    return http.request('get', `/entity/${entityId}/income-statement`)
  },
  /**
   * 获取cash-flow-statement
   * @param {number} entityId 主体id
   * @returns
   */
  getCashFlowStatement: (entityId: number) => {
    return http.request('get', `/entity/${entityId}/cash-flow-statement`)
  },
  /**
   * 获取restrictions-of-crypto-assets
   * @param {number} entityId 主体id
   * @returns
   */
  getRestrictionsOfCryptoAssets: (entityId: number) => {
    return http.request('get', `/entity/${entityId}/restrictions-of-crypto-assets`)
  },
  /**
   * 获取crypto-assets-roll-forward
   * @param {number} entityId 主体id
   * @returns
   */
  getCryptoAssetsRollForward: (entityId: number) => {
    return http.request('get', `/entity/${entityId}/crypto-assets-rollforward`)
  },
  /**
   * 获取significant-holdings
   * @param {number} entityId 主体id
   * @returns
   */
  getSignificantHoldings: (entityId: number) => {
    return http.request('get', `/entity/${entityId}/significant-holdings`)
  },
  /**
   * 获取计算弹窗的公式数据
   * @param {number} entityId
   * @param {string} name
   * @param {Object} data
   * @property {string} fieldId
   */
  getComputeAggregationApi: (entityId: number, name: string, data: ComputeAggregationParamsType) => {
    return http.request('post', `/entity/${entityId}/${name}/formula`, { data })
  },
  /**
   * 获取计算弹窗的科目第一个表格数据
   * @param {number} entityId
   * @param {ComputeAccountParamsType} data
   * @returns
   */
  getComputeAccountApi: (entityId: number, data: ComputeAccountParamsType) => {
    return http.request('post', `/entity/${entityId}/account/note/l1`, {
      data
    })
  },
  /**
   * 获取计算弹窗的科目第二个表格数据
   * @param {number} entityId
   * @param {ComputeEventParamsType} data
   * @returns
   */
  getComputeEventApi: (entityId: number, data: ComputeEventParamsType) => {
    return http.request('post', `/entity/${entityId}/account/note/l2`, {
      data
    })
  },
  /**
   * 获取general-ledger列表
   * @param {number} entityId 主体id
   * @param {Object} params
   * @property {string} accountId fieldId
   * @property {number} page 页码
   * @property {number} limit 每页条数
   * @returns
   */
  getGeneralLedgerList: (entityId: number, params: any) => {
    return http.request('get', `/entity/${entityId}/general-ledger/list`, { params })
  },
  /**
   * 获取Transaction详情
   * @param {number} entityId 主体id
   * @param {number} activityId 交易id
   */
  getTransactionDetail: (entityId: number, activityId: number) => {
    return http.request<ResponseData<TransactionDetailType>>('get', `/entity/${entityId}/activity/${activityId}`)
  },
  /**
   * 获取Transaction详情分录
   * @param {number} entityId 主体id
   * @param {number} transactionId
   * @returns
   */
  getTransactionDetailDivide(entityId: number, transactionId: number) {
    return http.request('get', `/entity/${entityId}/journal/transaction/${transactionId}`)
  },
  /**
   * 获取Duplicate列表
   * @param {number} entityId 主体id
   * @returns
   */
  getDuplicateList: (entityId: number) => {
    return http.request<ResponseData<DuplicateListType>>('get', `/entity/${entityId}/activity/duplicate`)
  },
  /**
   * keep all忽略
   * @param {number} entityId 主体id
   * @param {Object} data
   * @property {Array} activityIds 交易id
   */
  ignoreDuplicateKeepAll: (entityId: number, data: object) => {
    return http.request('post', `/entity/${entityId}/activity/keep`, { data })
  },
  /**
   * 获取For review 列表、批量审阅列表、categorized列表
   * @param {number} entityId 主体id
   * @param {Object} data
   * @property {number} page 页码
   * @property {number} limit 每页条数
   * @property {string} reviewStatus ForReview 和 批量审阅列表都传  `FOR_REVIEW` | categorized列表传 `REVIWED`
   * @property {string} fromAddress? 批量审阅-转出地址
   * @property {string} toAddress? 批量审阅-转入地址
   * @property {string} currency? 批量审阅-币种
   * @property {string} categoryNo? 批量审阅-业务活动标签id
   * @property {number} direction 交易方向
   * @property {number[]} sourceIds 交易来源id
   * @property {string[]} eventIds 事件id
   * @property {number[]} financialContactIds 财务联系人id
   * @property {string} fromDate 交易日期
   * @property {string} toDate 交易日期
   * @returns
   */
  getActivityList: (entityId: number, data: object) => {
    return http.request<ResponseData<TransactionListType>>('post', `/entity/${entityId}/activity`, {
      data
    })
  },
  /**
   * 获取Activity已有的event
   * @param {number} entityId 主体id
   * @param {Object} params
   * @property {Array} status REVIEWED | FOR_REVIEW
   */
  getActivityEvent: (entityId: number, params: object) => {
    return http.request('get', `/entity/${entityId}/activity/event`, { params })
  },
  /**
   * 修改交易详情
   * @param {number} entityId 主体id
   * @param {number} activityId
   * @param {Object} data
   * @property {string} memo? 备注
   * @property {number[]} activityAttachmentIds 附件ids
   * @property {string} categoryNo? 业务活动标签id
   */
  editActivityDetail: (entityId: number, activityId: number, data: object) => {
    return http.request('put', `/entity/${entityId}/activity/${activityId}`, { data })
  },
  /**
   * 快速审阅/批量审阅
   * @param {number} entityId 主体id
   * @param {Object} data
   * @property {Array} activityIds 交易id
   */
  quickReview: (entityId: number, data: object) => {
    return http.request('put', `/entity/${entityId}/activity/review`, { data })
  },
  /**
   * 全部审阅
   * @param {number} entityId 主体id
   * @param {Object} data
   */
  allReview: (entityId: number, data: object) => {
    return http.request('put', `/entity/${entityId}/activity/review/all`, { data })
  },
  /**
   * 快捷批量修改业务活动标签
   * @param {number} entityId 主体id
   * @param {Object} data
   * @property {number[]} activityIds 交易ids
   * @property {string} categoryNo? 业务活动标签id
   */
  quickEditEvent: (entityId: number, data: object) => {
    return http.request('put', `/entity/${entityId}/batch/activity`, { data })
  },
  /**
   * 获取问题中心列表
   * @param {number} entityId 主体id
   */
  getIssueList: (entityId: number) => {
    return http.request<ResponseData<ReportIssueType[]>>('post', `/entity/${entityId}/resolution-center`)
  },
  /**
   * 获取交易来源列表
   * @param {number} entityId 主体id
   * @param {Object} params
   * @property {string} type 交易来源类型 WALLET/EXCHANGE/CSV/API
   */
  getSourceList: (entityId: number, params?: any) => {
    return http.request('get', `/entity/${entityId}/source`, { params })
    // return http.request('get', `/entity/${entityId}/source`, { params })
  },
  /**
   * csv文件上传
   * @param {number} entityId 主体id
   * @param {Object} data
   * @property {string} templateType csv模板类型
   * @property {string} file csv文件
   */
  uploadCsv: (entityId: number, data: object) => {
    return http.request('post', `/entity/${entityId}/source/upload_bank_csv`, { data })
  },
  /**
   * 添加交易来源
   * @param {number} entityId 主体id
   * @param {Object} data
   * @property {string} type 交易来源类型 WALLET/EXCHANGE/CSV
   * @property {string} purpose 交易来源用途 operating/financing/investing
   * @property {string} PlatformId? 平台id
   * @property {string} address? 钱包地址
   * @property {string} apiKey? api key
   * @property {string} apiSecret? api secret
   * @property {string} sourceName 交易来源名称
   * @property {number} entityFileId? 项目文件id
   * @property {number} cardNumber? 卡号
   * @property {number} entityFileId? 项目账户id
   */
  addSource: (entityId: number, data: object) => {
    return http.request('post', `/entity/${entityId}/source`, { data })
  },
  /**
   * 修改交易来源
   * @param {number} entityId 主体id
   * @param {number} sourceId 交易来源id
   * @param {Object} data
   * @property {string} type 交易来源类型 WALLET/EXCHANGE/CSV
   * @property {string} name 项目账户名称
   * @property {string} apiKey? api key
   * @property {string} apiSecret? api secret
   */
  editSource: (entityId: number, sourceId: number, data: object) => {
    return http.request('put', `/entity/${entityId}/source/${sourceId}`, { data })
  },
  /**
   * 获取交易来源详情
   * @param {number} entityId 主体id
   * @param {number} sourceId 交易来源id
   */
  getSourceDetail: (entityId: number, sourceId: number) => {
    return http.request('get', `/entity/${entityId}/source/${sourceId}`)
  },
  /**
   * 删除交易来源
   * @param {number} entityId 主体id
   * @param {number} sourceId 交易来源id
   */
  deleteSource: (entityId: number, sourceId: number) => {
    return http.request('post', `/entity/${entityId}/source/${sourceId}/delete`)
  },
  /**
   * 获取Account列表
   * @param {number} entityId 主体id
   */
  getProjectAccountList: (entityId: number) => {
    return http.request('get', `/entity/${entityId}/entity-account`)
  },
  /**
   * 修改Account
   * @param {number} entityId 主体id
   * @param {number} entityFileId 项目账户id
   * @param {Object} data
   * @property {string} name 项目账户名称
   * @property {string} memo 项目账户备注
   */
  editProjectAccount: (entityId: number, entityFileId: number, data: object) => {
    return http.request('put', `/entity/${entityId}/entity-account/${entityFileId}`, {
      data
    })
  },
  /**
   * 批量删除Account
   * @param {number} entityId 主体id
   * @param {Object} data
   * @property {Array} entityAccountIds
   */
  deleteProjectAccount: (entityId: number, data: object) => {
    return http.request('post', `/entity/${entityId}/batch/entity-account/delete`, {
      data
    })
  },
  /**
   * 获取Contact列表
   * @param {number} entityId 主体id
   */
  getContactList: (entityId: number) => {
    return http.request('get', `/entity/${entityId}/contact`)
  },
  /**
   * 获取ContactUnnamed列表
   * @param {number} entityId 主体id
   * @param {Object} params
   * @property {number} page 页码
   * @property {number} limit 每页条数
   */
  getContactUnnamedList: (entityId: number, params: any) => {
    return http.request('get', `/entity/${entityId}/contact/unnamed`, { params })
  },
  /**
   * 批量修改Contact
   * @param {number} entityId 主体id
   * @param {Object} data
   * @property {Array} financialContactIds 联系人id
   * @property {string} role 联系人类型
   */
  editBatchContact: (entityId: number, data: object) => {
    return http.request('put', `/entity/${entityId}/batch/contact`, { data })
  },
  /**
   * 添加Contact
   * @param {number} entityId 主体id
   * @param {Object} data
   * @property {string} name 联系人名称
   * @property {string} role 联系人类型
   * @property {string} memo 联系人备注
   * @property {string} value 联系人卡号
   * @property {number} platformId 平台id
   */
  addContact: (entityId: number, data: object) => {
    return http.request('post', `/entity/${entityId}/contact`, { data })
  },
  /**
   * 添加Contact
   * @param {number} entityId 主体id
   * @param {Object} data
   * @property {Array} contacts 联系人数组
   * @property {string} name 联系人名称
   * @property {string} role 联系人类型
   * @property {string} memo 联系人备注
   * @property {string} value 联系人卡号
   * @property {number} platformId 平台id
   */
  addBatchContact: (entityId: number, data: object) => {
    return http.request('post', `/entity/${entityId}/batch/contact`, { data })
  },
  /**
   * 修改Contact
   * @param {number} entityId 主体id
   * @param {number} financialContactId 联系人id
   * @param {Object} data
   * @property {string} name 联系人名称
   * @property {string} role 联系人类型
   * @property {string} memo 联系人备注
   */
  editContact: (entityId: number, financialContactId: number, data: object) => {
    return http.request('put', `/entity/${entityId}/contact/${financialContactId}`, { data })
  },
  /**
   * 删除Contact
   * @param {number} entityId 主体id
   * @param {Object} data
   * @property {Array} financialContactIds 联系人id
   */
  deleteContact: (entityId: number, data: object) => {
    return http.request('post', `/entity/${entityId}/batch/contact/delete`, { data })
  },
  /**
   * 重新生成报表
   * @param {number} entityId 主体id
   */
  getRegenerateReport: (entityId: number) => {
    return http.request('get', `/entity/${entityId}/regenerate-report`)
  },
  /**
   * 获取source同步状态
   * @param {number} entityId 主体id
   */
  getSourceSyncStatus: (entityId: number) => {
    return http.request('get', `/entity/${entityId}/source/doing-count`)
  },
  /**
   * 获取配置规则列表
   * @param {number} entityId 主体id
   */
  getSourceConfigRuleList: (entityId: number) => {
    return http.request('get', `/entity/${entityId}/configRule`)
  },
  /**
   * 添加配置规则
   * @param {number} entityId 主体id
   * @param {Object} data
   * @property {string} name 规则名称
   * @property {Array} conditions 规则
   * @property {string} categoryNo event
   * @property {boolean} autoReviewed 是否自动审阅
   */
  addSourceConfigRule: (entityId: number, data: object) => {
    return http.request('post', `/entity/${entityId}/configRule`, { data })
  },
  /**
   * 修改配置规则
   * @param {number} entityId 主体id
   * @param {number} configRuleId 规则id
   * @param {Object} data
   * @property {string} name 规则名称
   * @property {Array} conditions 规则
   * @property {string} categoryNo event
   * @property {boolean} autoReviewed 是否自动审阅
   */
  editSourceConfigRule: (entityId: number, configRuleId: number, data: object) => {
    return http.request('put', `/entity/${entityId}/configRule/${configRuleId}`, { data })
  },
  /**
   * 删除规则
   * @param {number} entityId 主体id
   * @param {number} configRuleId 规则id
   */
  deleteSourceConfigRule: (entityId: number, configRuleId: number) => {
    return http.request('delete', `/entity/${entityId}/configRule/${configRuleId}`)
  },
  /**
   * 规则启用
   * @param {number} entityId 主体id
   * @param {number} configRuleId 规则id
   */
  enableSourceConfigRule: (entityId: number, configRuleId: number) => {
    return http.request('post', `/entity/${entityId}/configRule/${configRuleId}/enable`)
  },
  /**
   * 规则禁用
   * @param {number} entityId 主体id
   * @param {number} configRuleId 规则id
   */
  disableSourceConfigRule: (entityId: number, configRuleId: number) => {
    return http.request('post', `/entity/${entityId}/configRule/${configRuleId}/disable`)
  },
  /**
   * 生成activity
   * @param {number} entityId 主体id
   */
  generateActivity: (entityId: number) => {
    return http.request('post', `/entity/${entityId}/generate/activity`)
  },
  /**
   * 修改rule优先级
   * @param {number} entityId 主体id
   * @param {Object} data
   * @property {number[]} configRuleIds 规则id
   */
  editRulePriority: (entityId: number, data: object) => {
    return http.request('post', `/entity/${entityId}/configRule/modifyPriority`, { data })
  },
  /**
   * 获取Entity授权erp
   * @param {number} entityId 主体id
   * @param {Object} data
   * @property {number} code // 回调后的code
   * @property {string} redirectUri // 回调地址
   * @property {string} type // erp类型 XERO
   */
  getEntityAuthErp: (entityId: number, data: object) => {
    return http.request('post', `/entity/${entityId}/integration/access`, { data })
  },
  /**
   * 取消Entity授权erp
   * @param {number} entityId 主体id
   */
  cancelEntityAuthErp: (entityId: number) => {
    return http.request('delete', `/entity/${entityId}/integration/access/cancel`)
  },
  /**
   * 获取科目映射列表
   * @param {number} entityId 主体id
   */
  getAccountMappingList: (entityId: number) => {
    return http.request('get', `/entity/${entityId}/integration/account-mapping`)
  },
  /**
   * 科目映射修改
   * @param {number} entityId 主体id
   * @param {Array} data
   */
  editAccountMapping: (entityId: number, data: object) => {
    return http.request<ResponseData<AccountMappingItemType>>(
      'put',
      `/entity/${entityId}/integration/account-mapping`,
      { data }
    )
  },
  /**
   * 同步分录ERP
   * @param {number} entityId 主体id
   */
  syncJournalIntegration: (entityId: number) => {
    return http.request('post', `/entity/${entityId}/integration/sync-journal-mapping`)
  },
  /**
   * 获取dashboard详情
   * @param {number} entityId 主体id
   */
  getDashboardDetail: (entityId: number) => {
    return http.request<ResponseData<DashboardDataType>>('get', `/entity/${entityId}/dashboard`)
  }
}
